






















































































































import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'default-template',
  setup() {
    const { content } = useGetters(['content'])

    return {
      content,
    }
  },
})
